import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import Featured
from '@fuww/library/src/Articles/Sections/Featured';
import BigCard from '@fuww/library/src/Cards/Big';
import { SmallCard } from '@fuww/library/src/Cards/Small';
import WideCard from '@fuww/library/src/Cards/Wide';
import breakpoints from '@fuww/library/src/Theme/breakpoints';
import DynamicSideBar from './DynamicSidebar';
import LinkAndAnchor from '../../../LinkAndAnchor';
import { useSiteContext } from '../../../SiteContext';
import getArticlesParameters from '../../../../lib/getArticlesParameters';
import getProxiedImages from '../../../../lib/getProxiedImages';
import ImageWithUrls from '../../../ImageWithUrls';
import FormattedDate from '../../../FormattedDate';
import InView from '../../../InView';
import getCategoryTitle from '../../../../lib/getCategoryTitle';

const FeaturedSection = ({
  featuredArticles, variables, showCreatedAt, showLoader,
}) => {
  const intl = useIntl();
  const { hasLocalNews } = useSiteContext();

  const hasData = featuredArticles && featuredArticles.length > 0;
  const [firstArticle, secondArticle, thirdArticle, fourthArticle] = hasData
    ? featuredArticles : [null, null, null, null];

  const {
    imageUrls: firstArticleImageUrls = [],
    srcSet: firstArticleSourceSet = '',
  } = firstArticle ? getProxiedImages(variables, firstArticle.imageUrls) : {};

  const {
    imageUrls: fourthArticleImageUrls,
    srcSet: fourthArticleSourceSet,
  } = fourthArticle
    ? getProxiedImages(
      variables, fourthArticle.imageUrls,
    ) : {};

  const spotlightArticleContent = hasData
    ? (
      <LinkAndAnchor
        route="article"
        params={getArticlesParameters(firstArticle)}
        href={firstArticle?.path}
        useRouter
      >
        <BigCard
          showLoader={showLoader}
          image={(
            <ImageWithUrls
              imageUrls={firstArticleImageUrls}
              alt={firstArticle.title}
              sizes={`
                  (min-width: 1440px) 621px,
                  (min-width: ${breakpoints.lg}px) 44vw,
                  (min-width: ${breakpoints.md}px) 62vw,
                  (min-width: ${breakpoints.sm}px) 97vw,
                  100vw
                `}
              srcSet={firstArticleSourceSet}
              loadStrategy="instant"
              finalFetchpriority="high"
              placeholderFetchpriority="high"
              preload
            />
            )}
          category={
              getCategoryTitle(firstArticle.category, hasLocalNews, intl)
            }
          labels={firstArticle.labels}
          insertedAt={showCreatedAt
              && <FormattedDate value={firstArticle.insertedAt} />}
          title={firstArticle.title}
          description={firstArticle.summary}
          withPageMargins
        />
      </LinkAndAnchor>
    ) : (showLoader ? <BigCard showLoader /> : null);

  const sidebarArticlesContent = hasData
    ? [secondArticle, thirdArticle].map((newsArticle) => {
      if (!newsArticle) {
        return null;
      }

      const {
        category,
        title,
        summary,
        insertedAt,
        imageUrls,
        path,
        id,
        labels,
      } = newsArticle;

      const {
        imageUrls: newsArticleImageUrls,
        srcSet: newsArticleSourceSet,
      } = getProxiedImages(variables, imageUrls);

      return (
        <Fragment key={id}>
          <LinkAndAnchor
            route="article"
            params={getArticlesParameters(newsArticle)}
            href={path}
            useRouter
          >
            <SmallCard
              showLoader={showLoader}
              image={(
                <ImageWithUrls
                  imageUrls={newsArticleImageUrls}
                  alt={title}
                  sizes={`
                    (min-width: 1440px) 335px,
                    (min-width: ${breakpoints.lg}px) 24vw,
                    (min-width: ${breakpoints.md}px) 33vw,
                    (min-width: ${breakpoints.sm}px) 47vw,
                    100vw
                  `}
                  srcSet={newsArticleSourceSet}
                />
              )}
              category={getCategoryTitle(category, hasLocalNews, intl)}
              labels={labels}
              insertedAt={
                showCreatedAt && <FormattedDate value={insertedAt} />
              }
              title={title}
              description={summary}
              withPageMargins
            />
          </LinkAndAnchor>
        </Fragment>
      );
    }) : (showLoader ? [
      <SmallCard key="skeleton-1" showLoader />,
      <SmallCard key="skeleton-2" showLoader />,
    ] : null);

  const wideArticleContent = showLoader ? (
    <WideCard showLoader />
  ) : (fourthArticle ? (
    <LinkAndAnchor
      route="article"
      params={getArticlesParameters(fourthArticle)}
      href={fourthArticle.path}
      useRouter
    >
      <WideCard
        showLoader={showLoader}
        category={
            getCategoryTitle(fourthArticle.category, hasLocalNews, intl)
          }
        title={fourthArticle.title}
        insertedAt={showCreatedAt
            && <FormattedDate value={fourthArticle.insertedAt} />}
        description={fourthArticle.summary}
        imageUrls={fourthArticle.imageUrls}
        labels={fourthArticle.labels}
        image={(
          <ImageWithUrls
            imageUrls={fourthArticleImageUrls}
            alt={fourthArticle.title}
            sizes={`
                (min-width: 1440px) 503px,
                (min-width: ${breakpoints.lg}px) 35vw,
                (min-width: ${breakpoints.sm}px) 49vw,
                100vw
              `}
            srcSet={fourthArticleSourceSet}
          />
          )}
        withPageMargins
      />
    </LinkAndAnchor>
  ) : null);

  return (
    <Featured
      spotlightArticle={spotlightArticleContent}
      sidebarArticles={sidebarArticlesContent}
      wideArticle={wideArticleContent}
      sideBar={
          (
            <InView>
              <DynamicSideBar />
            </InView>
          )
        }
    />
  );
};

FeaturedSection.propTypes = {
  featuredArticles: PropTypes.arrayOf(PropTypes.shape({})),
  variables: PropTypes.shape({}).isRequired,
  showCreatedAt: PropTypes.bool,
  showLoader: PropTypes.bool,
};

FeaturedSection.defaultProps = {
  featuredArticles: [],
  showCreatedAt: false,
  showLoader: false,
};

export default FeaturedSection;
