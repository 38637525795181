import React from 'react';
import { InferProps } from 'prop-types';
import styled from '@emotion/styled';
import {
  CardOverline, CardDate,
  CardTextContainer, CardContainer, FullWidthImageContainer,
  CardBodyText,
} from '../Card';
import Headline4 from '../Typography/Heading/Headline4';
import Labels from '../Card/Labels';
import breakpoints from '../Theme/breakpoints';
import cardPropertyTypes from './propertyTypes';
import Skeleton from '../SkeletonLoader/Skeleton';
import { DateSkeleton, TitleSkeleton } from '../SkeletonLoader/Card';
import DescriptionSkeleton from '../SkeletonLoader/Default';

const StyledCardContainer = styled(CardContainer)`
  padding: 0 0 24px;

  @media (min-width: ${breakpoints.sm}px) {
    padding: 0 0 16px;
    flex-basis: calc(50% - ((16px + 16px + 1px) / 2 ));
  }

  @media (min-width: ${breakpoints.sm}px) {
    padding: 0 0 24px;
    flex-basis: auto;
  }
`;

const StyledHeadline4 = styled(Headline4)`
  &&  {
    margin: 0;

    @media (min-width: ${breakpoints.sm}px) {
      font-size: var(--size-h5);
      line-height: 1.75rem;
    }
  }
`;

const StyledCardBodyText = styled(CardBodyText)`

  @media (min-width: ${breakpoints.sm}px) {
    && {
    display: none;
    }
  }
`;

const FlexSmallCardContainer = styled.div`

  @media (min-width: ${breakpoints.sm}px)  {
    display: flex;
    border-top: 1px solid var(--grey-light);
    padding: 16px 0 0;

    a {
      padding: 0 16px 0 0;
      border-right: 1px solid var(--grey-light);
      flex-basis: calc(50% - 16px);

      &:last-of-type {
        border: none;
        padding: 0 0 0 16px;
      }
    }
  }

  @media (min-width: ${breakpoints.md}px) {
    display: block;
    padding: 0;

    a {
      padding: 0;
      border-right: none;

      &:last-of-type {
        padding: 0;
      }
    }
  }

  @media (min-width: ${breakpoints.lg}px) {
    flex-wrap: nowrap;
    display: block;
    border-top: none;
  }
`;

const StyledCardTextContainer = styled(CardTextContainer)`

  @media (min-width: ${breakpoints.sm}px) {
    padding: 16px 0 0;
  }
`;

type SmallCardProperties = InferProps<typeof cardPropertyTypes> & {
  withPageMargins?: boolean;
};

const SmallCard = ({
  title,
  category,
  insertedAt,
  image,
  labels = [],
  description,
  withPageMargins = false,
  showLoader = false,
} : SmallCardProperties) => (
  <StyledCardContainer>
    <FullWidthImageContainer withPageMargins={withPageMargins}>
      {showLoader ? (
        <Skeleton variant="rectangular" width="100%" height="100vh" />
      ) : image}
    </FullWidthImageContainer>
    <StyledCardTextContainer>
      <>
        {showLoader ? (
          <Skeleton
            variant="rectangular"
            width="100px"
          >
            &nbsp;
          </Skeleton>
        ) : (
          <CardOverline>
            {category}
            <Labels labels={labels} />
          </CardOverline>
        )}
        <StyledHeadline4 tag="h2">
          {showLoader ? <TitleSkeleton /> : title}
        </StyledHeadline4>
        <StyledCardBodyText>
          {showLoader ? (
            <DescriptionSkeleton />
          ) : description}
        </StyledCardBodyText>
      </>

      {showLoader ? (
        <CardDate>
          <DateSkeleton />
        </CardDate>
      ) : insertedAt && (
        <CardDate>
          {insertedAt}
        </CardDate>
      )}
    </StyledCardTextContainer>
  </StyledCardContainer>
);

SmallCard.propTypes = cardPropertyTypes;

export { SmallCard, FlexSmallCardContainer };
